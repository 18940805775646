:root {
    --clr-primary: #0D121D;
    --clr-secondary: #121A29;
    --clr-neutral: #767F92;
  
    --clr-white: #F1F2F9;
    --clr-blue: #2187D0;
    --clr-red: #F45353;
    --clr-green: #25CE8F;
  }

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: var(--clr-primary);
}

.home-logo {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.center-login-text {
  margin-left: 100px;
}